import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AssessmentIcon from "@material-ui/icons/Assessment";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseIcon from "@material-ui/icons/Close";
import SubjectIcon from "@material-ui/icons/Subject";
import BuildIcon from "@material-ui/icons/Build";
import PersonIcon from "@material-ui/icons/Person";

// import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
// import Notifications from "@material-ui/icons/Notifications";
// import Unarchive from "@material-ui/icons/Unarchive";
// import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import PedidosRecusados from "views/PedidosRecusados";
import ClientTableList from "views/ClientTableList";
import Caradapio from "views/Cardapio";

import CriarCategorias from "views/CriarCategorias";
import CriarProdutos from "views/CriarProdutos";
import Relatorios from "views/Relatorios";

import Cupons from "views/Cupons";
import Login from "views/Login/Login.js";
// import Typography from "views/Typography/Typography.js";
// import Icons from "views/Icons/Icons.js";
import Maps from "views/Maps";

import NewOrder from "views/NewOrder";

import { Add } from "@material-ui/icons";

// import NotificationsPage from "views/Notifications/Notifications.js";

const dashboardRoutes = [
  {
    path: "/new-order",
    name: "Novo Pedido",
    icon: Add,
    component: NewOrder,
    layout: "/admin",
  },
  {
    path: "/dashboard",
    name: "Início",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/clientes",
    name: "Clientes",
    icon: PersonIcon,
    component: ClientTableList,
    layout: "/admin",
  },
  {
    path: "/cardapio",
    name: "Cardápio",
    icon: RestaurantIcon,
    component: Caradapio,
    layout: "/admin",
  },
  {
    path: "/pedidos-fechados",
    name: "Pedidos Fechados",
    icon: "content_paste",
    component: TableList,
    layout: "/admin",
  },

  {
    path: "/pedidos-excluidos",
    name: "Pedidos Recusados",
    icon: CloseIcon,
    component: PedidosRecusados,
    layout: "/admin",
  },

  {
    path: "/criar-categorias",
    name: "Criar Categorias",
    icon: SubjectIcon,
    component: CriarCategorias,
    layout: "/admin",
  },

  {
    path: "/criar-produtos",
    name: "Criar Produtos",
    icon: AddCircleOutlineIcon,
    component: CriarProdutos,
    layout: "/admin",
  },

  {
    path: "/relatorios",
    name: "Relatórios",
    icon: AssessmentIcon,
    component: Relatorios,
    layout: "/admin",
  },

  {
    path: "/cupons",
    name: "Cupons",
    icon: LocalOfferIcon,
    component: Cupons,
    layout: "/admin",
  },

  {
    path: "/areas-entrega",
    name: "Áreas de Entrega",

    icon: LocationOn,
    component: Maps,
    layout: "/admin",
  },

  {
    path: "/user",
    name: "Configurações",
    icon: BuildIcon,
    component: UserProfile,
    layout: "/admin",
  },

  // {
  //   path: "/typography",
  //   name: "Typography",

  //   icon: LibraryBooks,
  //   component: Typography,
  //   layout: "/admin"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",

  //   icon: BubbleChart,
  //   component: Icons,
  //   layout: "/admin"
  // },

  // {
  //   path: "/notifications",
  //   name: "Notifications",

  //   icon: Notifications,
  //   component: NotificationsPage,
  //   layout: "/admin"
  // }
];

export default dashboardRoutes;
