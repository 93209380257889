import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import moment from "moment";
import swal from "sweetalert2";

import api from "../../services/api";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import darkStyles from "assets/jss/material-dashboard-react/views/darkDashboardStyle.js";

export default function CuponsList({ refreshData }) {
  const dark = useSelector((state) => state.dark);
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const useStyles = makeStyles(store.isDark.status ? darkStyles : styles);
  const classes = useStyles();
  const [cupons, setCupons] = useState([]);
  const [localTime, setLocalTime] = useState("");

  const [open, setOpen] = useState(false);

  const [cupom, setCupom] = useState();

  const [quantidadeUsados, setQuantidadeUsados] = useState(0);

  const token = localStorage.getItem("token");

  useEffect(() => {
    async function loadCupons() {
      await api
        .get(
          "busca-cupons",
          { headers: { Authorization: `Bearer ${token}` } },
          { timeout: 10000 }
        )
        .then((response) => {
          setCupons(response.data);
          // console.log(response.data);
        });
      await api
        .get("https://worldtimeapi.org/api/ip", { timeout: 10000 })
        .then((response) => {
          setLocalTime(response.data.datetime);
          // alert(moment(response.data.datetime));
        });
    }
    loadCupons();
  }, [refreshData]);

  async function handleDelete(id) {
    api
      .delete(
        `deleta-cupom/${id}`,
        { headers: { Authorization: `Bearer ${token}` } },
        { timeout: 10000 }
      )
      .then((response) => {
        if (response.data) {
          swal.fire({
            html: "<h4>O cupom foi excluído</h4><br />",
            icon: "error",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            timer: 7000,
          });
          api.get("busca-cupons", { timeout: 10000 }).then((response) => {
            setCupons(response.data);
          });
        }
      });
  }

  async function handleSelectCupom(id) {
    api
      .get(
        `busca-cupon/${id}`,
        { headers: { Authorization: `Bearer ${token}` } },
        { timeout: 10000 }
      )
      .then((response) => {
        setCupom(response.data.cupom);
        setQuantidadeUsados(response.data.quantidadeUsados);
        setOpen(true);
      });
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card lassName={classes.cardTable1}>
            <CardHeader className={classes.cardTable}>
              <h4 className={classes.cardTitleWhite}>Cupons Ativos</h4>
            </CardHeader>
            <CardBody className={classes.cardBody}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow className={classes.tableHeadRow}>
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                    >
                      Cupom
                    </TableCell>
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                    >
                      Data Ínicio
                    </TableCell>
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                    >
                      Data Final
                    </TableCell>
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                    >
                      Desconto %
                    </TableCell>
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                    >
                      Usados
                    </TableCell>

                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                    >
                      Excluir
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cupons.map((cupom) => (
                    <>
                      {moment(localTime).isAfter(cupom.inicio_validade) &&
                      moment(localTime).isBefore(cupom.fim_validade) ? (
                        <TableRow
                          key={cupom.id}
                          className={classes.tableBodyRow}
                          onClick={() => handleSelectCupom(cupom.id)}
                        >
                          <TableCell className={classes.tableCell}>
                            {cupom.cupom}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {moment(cupom.inicio_validade).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {moment(cupom.fim_validade).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {cupom.porcentagem_desconto}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {cupom.qtd}
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {" "}
                            <DeleteIcon
                              onClick={() => handleDelete(cupom.id)}
                            />
                          </TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                </TableBody>
              </Table>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card className={classes.cardTable1}>
            <CardHeader className={classes.cardTable}>
              <h4 className={classes.cardTitleWhite}>
                Cupons Inativos ou Expirados
              </h4>
            </CardHeader>
            <CardBody className={classes.cardBody}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow className={classes.tableHeadRow}>
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                    >
                      Cupom
                    </TableCell>
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                    >
                      Data Ínicio
                    </TableCell>
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                    >
                      Data Final
                    </TableCell>
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                    >
                      Desconto %
                    </TableCell>

                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                    >
                      Usados
                    </TableCell>

                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                    >
                      Excluir
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cupons.map((cupom) => (
                    <>
                      {(moment(localTime).isBefore(cupom.inicio_validade) &&
                        moment(localTime).isAfter(cupom.fim_validade)) ||
                      (moment(localTime).isAfter(cupom.inicio_validade) &&
                        moment(localTime).isBefore(cupom.fim_validade)) ? (
                        ""
                      ) : (
                        <TableRow
                          key={cupom.id}
                          className={classes.tableBodyRow}
                          onClick={() => handleSelectCupom(cupom.id)}
                        >
                          <TableCell className={classes.tableCell}>
                            {cupom.cupom}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {moment(cupom.inicio_validade).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {moment(cupom.fim_validade).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {cupom.porcentagem_desconto}
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {cupom.qtd}
                          </TableCell>

                          {/* <TableCell>
                            {" "}
                            <EditIcon />
                          </TableCell> */}
                          <TableCell className={classes.tableCell}>
                            {" "}
                            <DeleteIcon
                              onClick={() => handleDelete(cupom.id)}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  ))}
                </TableBody>
              </Table>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Algum Título"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {cupom ? `Nome do Cupom: ${cupom.cupom} ` : ""}
            <br />
            <br />
            {cupom
              ? `Porcentagem de Desconto: ${cupom.porcentagem_desconto}%`
              : ""}
            <br />
            <br />
            Quantidade do Cupom Usado: {quantidadeUsados}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
