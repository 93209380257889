import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

// @material-ui/core components
import Loading from "../Loading";

import api from "../../services/api";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import { Grow } from "@material-ui/core";

import swal from "sweetalert2";

// core components
import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import TextField from "@material-ui/core/TextField";

import Table from "@material-ui/core/Table";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";

import TableBody from "@material-ui/core/TableBody";

import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Pagination from "@material-ui/lab/Pagination";

import "./styles.css";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import darkStyles from "assets/jss/material-dashboard-react/views/darkDashboardStyle.js";

export default function ClientTableList() {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const useStyles = makeStyles(store.isDark.status ? darkStyles : styles);
  const classes = useStyles();

  const [clientes, setClientes] = useState([]);
  const [totalClientes, setTotalClientes] = useState(0);

  const [cliente, setCliente] = useState([]);
  const [totalPedidosCliente, setTotalPedidosCliente] = useState(0);
  const [valorPedidosCliente, setValorPedidosCliente] = useState(0);

  const [openDialog, setOpenDialog] = useState(false);

  const [loading, setLoading] = useState(true);
  const [loadingPesquisa, setLoadingPesquisa] = useState(false);

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const [buscaCliente, setBuscaCliente] = useState("");
  const [refreshClientes, setRefreshClientes] = useState(0);

  const token = localStorage.getItem("token");

  useEffect(() => {
    setLoading(true);

    let timeout = null;

    const pesquisaInput = document.getElementById("pesquisa-cliente");
    pesquisaInput.addEventListener("keyup", function(e) {
      setLoadingPesquisa(true);
      clearTimeout(timeout);

      timeout = setTimeout(async function() {
        if (pesquisaInput.value.length > 3) {
          setLoading(true);
          await api
            .post(
              "procura-cliente",
              { buscaCliente: pesquisaInput.value },
              { headers: { Authorization: `Bearer ${token}` } },
              { timeout: 10000 }
            )
            .then(async (response) => {
              setClientes(response.data.rows);
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
              swal.fire({
                html:
                  "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
                icon: "warning",
                confirmButtonColor: "#2d3035",
                confirmButtonText: "Ok",
                //  timer: 5000
              });
            });
        } else if (pesquisaInput.value.length === 0) {
          setLoading(true);
          setRefreshClientes(refreshClientes + 1);
        }
      }, 888);
    });

    async function loadClientes() {
      await api
        .get(
          `clientes/${page}`,
          { headers: { Authorization: `Bearer ${token}` } },
          { timeout: 10000 }
        )
        .then(async (response) => {
          console.log(response.data);
          setClientes(response.data.data);
          setTotalClientes(response.data.total);

          // buscaPedidosCliente(response.data.data);
          setLastPage(response.data.lastPage);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);

          swal
            .fire({
              html:
                "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
              icon: "warning",
              confirmButtonColor: "#2d3035",
              confirmButtonText: "Ok",
              //  timer: 5000
            })
            .then(() => {
              // window.location.reload();
            });
          // setTimeout(() => window.location.reload(), 5000);
        });
    }

    loadClientes();
  }, [page, refreshClientes]);

  function handleChangePage(event, page) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      setPage(page);
    }, 100);
  }

  async function handleSelectCliente(idCliente) {
    setLoading(true);

    api
      .get(
        `dados-cliente/${idCliente}`,
        { headers: { Authorization: `Bearer ${token}` } },
        { timeout: 10000 }
      )
      .then(async (response) => {
        setCliente(response.data.cliente);
        setTotalPedidosCliente(response.data.totalPedidos);
        setValorPedidosCliente(response.data.valorPedidos);

        setTimeout(() => {
          setLoading(false);
        }, 777);
        setTimeout(() => {
          setOpenDialog(true);
        }, 600);
      })
      .catch(() => {
        setLoading(false);
        swal
          .fire({
            html:
              "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
            icon: "warning",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            //  timer: 5000
          })
          .then(() => {});
      });
  }

  const handleClose = () => {
    setOpenDialog(false);
    setCliente([]);
  };

  const formato = {
    minimumFractionDigits: 2,
  };

  return (
    <>
      <TextField
        name="pesquisar"
        label="Pesquisar por Cliente por nome ou telefone"
        id="pesquisa-cliente"
        // onKeyUp={(event) => handleProcuraCliente(event.target.value)}
        onChange={(event) => setBuscaCliente(event.target.value)}
        fullWidth
        className={classes.inputSearch}
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          <GridContainer>
            <br />
            <br />
            <br />
            <Grid xs={12} sm={12} md={12} className={classes.center}>
              <Typography variant="h6" className={classes.cardTitle}>
                {" "}
                Total de clientes registrados: {totalClientes}
              </Typography>
            </Grid>
            <br />

            <GridItem xs={12} sm={12} md={12}>
              <Card className={classes.cardTable1}>
                <CardHeader className={classes.cardTable}>
                  <h4 className={classes.cardTitleWhite}>Clientes</h4>
                </CardHeader>
                <CardBody className={classes.cardBody}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow className={classes.tableHeadRow}>
                        <TableCell
                          className={
                            classes.tableCell + " " + classes.tableHeadCell
                          }
                        >
                          ID
                        </TableCell>
                        <TableCell
                          className={
                            classes.tableCell + " " + classes.tableHeadCell
                          }
                        >
                          Nome
                        </TableCell>
                        <TableCell
                          className={
                            classes.tableCell + " " + classes.tableHeadCell
                          }
                        >
                          Telefone
                        </TableCell>
                        <TableCell
                          className={
                            classes.tableCell + " " + classes.tableHeadCell
                          }
                        >
                          Email
                        </TableCell>
                        <TableCell
                          className={
                            classes.tableCell + " " + classes.tableHeadCell
                          }
                        >
                          Registro
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {clientes.map((cliente) => (
                        <>
                          <TableRow
                            key={cliente.id}
                            className={classes.tableBodyRow}
                            onClick={() => handleSelectCliente(cliente.id)}
                          >
                            <TableCell className={classes.tableCell}>
                              {cliente.id}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {cliente.name}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {cliente.telefone}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {cliente.email}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {moment(cliente.created_at).format("DD/MM/YYYY")}
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                    <div className={classes.paginationDiv}>
                      <Pagination
                        count={lastPage}
                        page={page}
                        onChange={(event, page) =>
                          handleChangePage(event, page)
                        }
                        color="secondary"
                      />
                    </div>
                  </Table>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </>
      )}

      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleClose}
        // TransitionComponent={Grow}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              color="#000"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.titleOrder}>
              {/* {cliente.map((jow) => (
                <>&nbsp; &nbsp; &nbsp; {jow.name}</>
              ))} */}
            </Typography>
          </Toolbar>
        </AppBar>
        <br />
        <br />
        <br />
        <br />
        <GridContainer spacing={5}>
          <GridItem xs={12} sm={6} md={6} xl={6}>
            <Card className={classes.cardBody}>
              <CardHeader stats icon>
                <CardIcon color="danger">
                  <AssignmentOutlinedIcon />
                </CardIcon>

                <h3 className={classes.cardTitle}>
                  {totalPedidosCliente}
                  <br />
                </h3>
              </CardHeader>
              <CardFooter stat>
                <div className={classes.stats}>
                  <p className={classes.cardCategory}> Total de pedidos</p>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} xl={6}>
            <Card className={classes.cardBody}>
              <CardHeader stats icon>
                <CardIcon color="danger">
                  <AttachMoneyIcon />
                </CardIcon>

                <h3 className={classes.cardTitle}>
                  {cliente.length > 0 && cliente[0].pedidos.length > 0 ? (
                    <>
                      R${" "}
                      {parseFloat(valorPedidosCliente).toLocaleString(
                        "pt-BR",
                        formato
                      )}
                    </>
                  ) : (
                    "Sem pedidos"
                  )}
                  <br />
                </h3>
              </CardHeader>
              <CardFooter stat>
                <div className={classes.stats}>
                  <p className={classes.cardCategory}>
                    {" "}
                    Valor total dos pedidos
                  </p>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>

        <br />

        <GridContainer spacing={5}>
          <GridItem xs={12} sm={6} md={6} xl={6}>
            <>
              {cliente.length > 0 && cliente[0].pedidos.length > 0 ? (
                <>
                  <Typography variant="h6" className="text-center ">
                    Pedidos fechados
                  </Typography>
                  <br />
                  {cliente[0].pedidos.map((pedido) => (
                    <>
                      {pedido.status === "andamento" ? (
                        ""
                      ) : (
                        <>
                          <ExpansionPanel key={pedido.id}>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.heading}>
                                Pedido {pedido.id}
                                <br />
                                <small>
                                  R${" "}
                                  {parseFloat(
                                    pedido.valor_total
                                  ).toLocaleString("pt-BR", formato)}
                                  <br />
                                  {pedido.status === "finalizado"
                                    ? `Finalizado`
                                    : ""}
                                  {pedido.status === "pedido_online" ? (
                                    <>
                                      Aguardando ser aceito{" "}
                                      <Typography></Typography>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {pedido.status === "pedido_aceito"
                                    ? `Pedido Aceito`
                                    : ""}
                                  {pedido.status === "pedido_aberto"
                                    ? `Pedido Aberto`
                                    : ""}
                                  {pedido.status === "pedido_transito"
                                    ? `Pedido em Trânsito`
                                    : ""}
                                  <br />
                                  {moment(pedido.horario).format(
                                    "DD/MM/YYYY - HH:mm"
                                  )}
                                </small>
                              </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Typography>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  size="small"
                                  onClick={() => {}}
                                >
                                  Detalhes
                                </Button>
                              </Typography>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                          <br />
                        </>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <Typography variant="h6" className="text-center ">
                  Este cliente não possui pedidos fechados
                </Typography>
              )}
            </>
          </GridItem>

          <GridItem xs={12} sm={6} md={6} xl={6}>
            {cliente.length > 0 ? (
              <>
                {cliente.map((jow) => (
                  <>
                    <Typography variant="h6" className="text-center ">
                      Nome: {jow.name}
                    </Typography>
                    <br />
                    <Typography
                      variant="subtitle1"
                      className="text-center mt-15"
                    >
                      Tel: {jow.telefone}
                    </Typography>
                    <br />

                    <Typography
                      variant="subtitle1"
                      className="text-center mt-15"
                    >
                      E-mail: {jow.email}
                    </Typography>
                    <br />
                  </>
                ))}
                {cliente[0].enderecos.map((endereco) => (
                  <g>
                    {endereco.tipo_endereco === "Principal" ? (
                      <>
                        <Typography
                          variant="subtitle1"
                          className="text-center mt-15"
                        >
                          {endereco.logradouro}, {endereco.numero_casa}
                        </Typography>
                        <br />
                        <Typography
                          variant="subtitle1"
                          className="text-center mt-15"
                        >
                          {endereco.bairro} - {endereco.cep}
                        </Typography>
                        <br />
                        <Typography
                          variant="subtitle1"
                          className="text-center mt-15"
                        >
                          {endereco.localidade} - {endereco.uf}
                        </Typography>
                      </>
                    ) : (
                      ""
                    )}
                  </g>
                ))}
              </>
            ) : (
              ""
            )}
          </GridItem>
        </GridContainer>
      </Dialog>
    </>
  );
}
