export default async function convertAddressToLatLng(logradouro, numeroUsuario, bairro, cidade, cepUsuario) {
  const address = `${logradouro},${numeroUsuario}+${bairro},${cidade}+${cepUsuario}`;

  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyDFHF1iAJHYcHxbs9mS8gRiRjXQmtkbCRM`
  )
  // .then(function (response) {
  //   return response.json();
  // })
  // console.log('address')
  // console.log(address)
  // return address
  return response.json()
}