import React from 'react'
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import CurrencyInput from "react-currency-input";
import swal from "sweetalert2";


import { Grid, Typography } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import SwitchButton from "@material-ui/core/Switch";
import noImage from "assets/img/no-image.png";

import Button from "@material-ui/core/Button";



import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import darkStyles from "assets/jss/material-dashboard-react/views/darkDashboardStyle.js";
import { useState } from 'react';
import GridItem from 'components/Grid/GridItem';


export default function ProductsList({ categorias, subCategorias, produtos }) {

  const dark = false;
  const store = useSelector((state) => state);
  const useStyles = makeStyles(store.isDark.status ? darkStyles : styles);
  const classes = useStyles();

  const formato = {
    minimumFractionDigits: 2,
  };


  function selectProduct(produto) {
    if (produto.status === "indisponivel") {
      swal
        .fire({
          html:
            "<h4>Produto indisponível</h4>",
          icon: "error",
          confirmButtonColor: "#2d3035",
          confirmButtonText: "Ok",
          timer: 8000,
        })
    } else {
      swal
        .fire({
          html:
            "<h4>Criar modal para selecionar quantidade, obs e <br/> inserir produto no pedido</h4>",
          icon: "success",
          confirmButtonColor: "#2d3035",
          confirmButtonText: "Ok",
          timer: 8000,
        })
    }

  }
  // const [products, setProducts] = useState(productsMenu)

  // console.log('categorias')
  // console.log(categorias)

  // console.log('subCategorias')
  // console.log(subCategorias)

  // console.log('produtos')
  // console.log(produtos)


  // console.log('subCategorias')
  // console.log(subCategorias)

  return (
    <>
      {/* <GridContainer>
        <Grid>
          <Typography>

          </Typography>
        </Grid>
      </GridContainer> */}

      {categorias.map((categoria) => (
        <>
          <Accordion style={{ marginTop: 15, backgroundColor: "#202020", boxShadow: "0 0 4px 0 rgb(255 255 255 / 40%)" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              classes={{ content: classes.accordionContent }}
            >
              <Typography variant="h6" style={{ fontSize: '1rem', color: '#fff', fontWeight: '300' }}>
                {categoria.categoria}
              </Typography>
            </AccordionSummary>
            <AccordionDetails >
              {subCategorias.map((subCategoria) => (
                <>
                  {subCategoria.id_categoria === categoria.id ? (
                    <>
                      {/* <GridContainer>
                        <Grid
                          xs={12}
                          sm={12}
                          md={12}
                          className={classes.center}
                        >
                          <Typography
                            variant="h6"
                            className={classes.cardTitleSub}
                          >
                            {subCategoria.sub_categoria}
                          </Typography>
                        </Grid>
                      </GridContainer> */}
                      <GridContainer>

                        {produtos.map((produto) => (
                          <>
                            {produto.id_sub_categoria
                              == subCategoria.id ? (
                              <GridItem
                                xs={12}
                                sm={6}
                                md={3}
                                xl={3}
                                key={produto.id}
                              >
                                <Card
                                  className={produto.status === "indisponivel" ? classes.cardBodyIndisponivel : classes.cardBodyDisponivel}
                                  onClick={() => selectProduct(produto)}
                                >
                                  {/* <CardHeader stats icon>
                                  {produto.status === "indisponivel" ? (
                                    // <CardIcon color="danger">
                                    <ErrorOutlineIcon style={{ color: "red" }} />
                                    // </CardIcon>
                                  ) : (
                                    // <CardIcon
                                    //   className={classes.transitoTilte}
                                    //   color="success"
                                    // >
                                    <CheckCircleOutlineIcon style={{ color: "green" }} />
                                    // </CardIcon>
                                  )}
                                </CardHeader> */}
                                  <CardFooter
                                    stat
                                    className={classes.cardFooterCardapioNew}

                                  >
                                    <h4
                                      className={
                                        dark
                                          ? classes.cardTitleDark
                                          : classes.cardTitle
                                      }
                                    >
                                      {produto.produto}
                                    </h4>

                                    {/* <br />
                                    <p
                                      className={
                                        dark
                                          ? classes.cardTitleDark
                                          : classes.cardTitle
                                      }
                                    >
                                      {produto.descricao}
                                    </p>
                                    <br /> */}
                                    {/* {produto.imagem ? (
                                      <>
                                        <img
                                          className={classes.mediaCardapio}
                                          src={produto.imagem}
                                          alt={produto.produto}
                                        />
                                      </>
                                    ) : (
                                      <img
                                        src="https://s3.amazonaws.com/storage.n2delivery.com.br/no-image.jpg"
                                        alt={produto.produto}
                                        className={classes.mediaCardapio}
                                      />
                                    )} */}
                                    {/* <br /> */}
                                  </CardFooter>
                                  <Grid
                                    container
                                    direction="row"
                                    justify="space-around"
                                    alignItems="stretch"
                                    className={produto.status === "indisponivel" ? classes.cardButtonsIndisponivel : classes.cardButtonsDisponivel}
                                  >
                                    <p>
                                      <small>
                                        <>
                                          {produto.valor_gigante
                                            ? `GG R$ ${parseFloat(
                                              produto.valor_gigante
                                            ).toLocaleString(
                                              "pt-BR",
                                              formato
                                            )} \u00A0 `
                                            : ""}

                                          {produto.valor_grande
                                            ? `G R$ ${parseFloat(
                                              produto.valor_grande
                                            ).toLocaleString(
                                              "pt-BR",
                                              formato
                                            )} \u00A0 `
                                            : ""}

                                          {produto.valor_media
                                            ? `M R$ ${parseFloat(
                                              produto.valor_media
                                            ).toLocaleString(
                                              "pt-BR",
                                              formato
                                            )} \u00A0 `
                                            : ""}

                                          {produto.valor_brotinho
                                            ? `P R$ ${parseFloat(
                                              produto.valor_brotinho
                                            ).toLocaleString(
                                              "pt-BR",
                                              formato
                                            )} \u00A0 `
                                            : ""}
                                        </>
                                        <>
                                          {produto.valor
                                            ? `R$ ${parseFloat(
                                              produto.valor
                                            ).toLocaleString(
                                              "pt-BR",
                                              formato
                                            )} \u00A0 `
                                            : ""}
                                        </>
                                      </small>
                                    </p>
                                  </Grid>

                                  {/* <Grid
                                    container
                                    direction="row"
                                    justify="space-around"
                                    alignItems="stretch"
                                    className={classes.cardButtons}
                                  >
                                    {!produto.status ? (
                                      <FormControlLabel
                                        label="Disponível"
                                        control={
                                          <SwitchButton
                                            checked={true}
                                            // onChange={() =>
                                            //   handleIndisponivel(produto.id)
                                            // }
                                            name="checkedB"
                                            color="secondary"
                                            size="small"
                                          />
                                        }
                                      />
                                    ) : (
                                      <FormControlLabel
                                        label="Indisponível"
                                        control={
                                          <SwitchButton
                                            checked={false}
                                            // onChange={() =>
                                            //   handleDisponivel(produto.id)
                                            // }
                                            name="checkedB"
                                            color="secondary"
                                            size="small"
                                          />
                                        }
                                      />
                                    )}
                                    <Button
                                      variant="contained"
                                      className={classes.statusButton}
                                    // onClick={() =>
                                    //   handleDeleteProduto(produto.id)
                                    // }
                                    >
                                      <DeleteIcon />
                                    </Button>
                                    <Button
                                      variant="contained"
                                      className={classes.statusButton}
                                    // onClick={() =>
                                    //   handleSelectProduto(produto.id)
                                    // }
                                    >
                                      <EditIcon />
                                    </Button>
                                  </Grid> */}
                                </Card>
                              </GridItem>

                            ) : ('')}
                          </>
                        ))}
                      </GridContainer>
                    </>
                  ) : ('')}
                </>

              ))}

            </AccordionDetails>
          </Accordion>
        </>
      ))}


    </>
  )
}
