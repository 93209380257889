import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// @material-ui/core components
import Loading from "../Loading";

import api from "../../services/api";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import { Grow } from "@material-ui/core";

import swal from "sweetalert2";

// core components
import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "@material-ui/core/Table";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import TableBody from "@material-ui/core/TableBody";

import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

import Pagination from "@material-ui/lab/Pagination";

import "./styles.css";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import darkStyles from "assets/jss/material-dashboard-react/views/darkDashboardStyle.js";

export default function PedidosRecusados() {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const useStyles = makeStyles(store.isDark.status ? darkStyles : styles);
  const classes = useStyles();

  const [pedidosFechados, setPedidosFechados] = useState([]);
  const [buscaPedidos, setBuscaPedidos] = useState(false);

  const [detalhesPedido, setDetalhesPedido] = useState([]);
  const [cliente, setCliente] = useState([]);
  const [produtosPedido, setProdutosPedido] = useState([]);
  const [enderecoEntrega, setEnderecoEntrega] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);

  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [totalPedidosFechados, setTotalPedidosFechados] = useState("0");

  const token = localStorage.getItem("token");

  useEffect(() => {
    setLoading(true);
    // console.log("load novos pedidos");
    async function loadPedidosRecusados() {
      await api
        .get(
          `busca-pedidos-recusados/${page}`,
          { headers: { Authorization: `Bearer ${token}` } },
          { timeout: 10000 }
        )
        .then(async (response) => {
          //console.log(response.data);
          setPedidosFechados(response.data.data);
          setPage(response.data.page);
          setLastPage(response.data.lastPage);
          setTotalPedidosFechados(response.data.total);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);

          swal
            .fire({
              html:
                "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
              icon: "warning",
              confirmButtonColor: "#2d3035",
              confirmButtonText: "Ok",
              //  timer: 5000
            })
            .then(() => {
              // window.location.reload();
            });
          // setTimeout(() => window.location.reload(), 5000);
        });
    }

    loadPedidosRecusados();
  }, [page]);

  async function handleClickPedido(id_pedido) {
    // alert(id_pedido);

    // setIdPedido(id_pedido);
    setLoading(true);
    await api
      .get(
        `busca-pedido/${id_pedido}`,
        { headers: { Authorization: `Bearer ${token}` } },
        { timeout: 10000 }
      )
      .then(async (response) => {
        //console.log(response.data[0]);
        setDetalhesPedido(response.data[0]);
        setCliente(response.data[0].cliente);
        setProdutosPedido(response.data[0].produtosPedido);
        setEnderecoEntrega(response.data[0].cliente.enderecos[0]);

        setOpenDialog(true);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        swal
          .fire({
            html:
              "<h4>Ocorreu algum problema de conexão</h4><br /><h5>Por favor tente novamente</h5>",
            icon: "warning",
            confirmButtonColor: "#2d3035",
            confirmButtonText: "Ok",
            timer: 5000,
          })
          .then(() => {
            // window.location.reload();
          });
        // setTimeout(() => window.location.reload(), 5000);
      });
  }

  const handleClose = () => {
    setOpenDialog(false);
  };

  const formato = {
    minimumFractionDigits: 2,
  };

  function handleChangePage(event, page) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      setPage(page);
    }, 100);
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <GridContainer>
            <br />
            <Grid xs={12} sm={12} md={12} className={classes.center}>
              <Typography variant="h6" className={classes.cardTitle}>
                {" "}
                Total de pedidos recusados e excluídos: {totalPedidosFechados}
              </Typography>
            </Grid>
            <br />

            <GridItem xs={12} sm={12} md={12}>
              <Card className={classes.cardTable1}>
                <CardHeader className={classes.cardTable}>
                  <h4 className={classes.cardTitleWhite}>
                    Pedidos Recusados e Excluídos
                  </h4>
                </CardHeader>
                <CardBody className={classes.cardBody}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow className={classes.tableHeadRow}>
                        <TableCell
                          className={
                            classes.tableCell + " " + classes.tableHeadCell
                          }
                        >
                          N&ordm; Pedido
                        </TableCell>
                        <TableCell
                          className={
                            classes.tableCell + " " + classes.tableHeadCell
                          }
                        >
                          Cliente
                        </TableCell>
                        <TableCell
                          className={
                            classes.tableCell + " " + classes.tableHeadCell
                          }
                        >
                          Telefone
                        </TableCell>
                        <TableCell
                          className={
                            classes.tableCell + " " + classes.tableHeadCell
                          }
                        >
                          Data
                        </TableCell>
                        <TableCell
                          className={
                            classes.tableCell + " " + classes.tableHeadCell
                          }
                        >
                          Valor Total
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pedidosFechados.map((pedido) => (
                        <TableRow
                          key={pedido.id_pedido}
                          className={classes.tableBodyRow}
                          onClick={() => {
                            handleClickPedido(pedido.id_pedido);
                          }}
                        >
                          <TableCell className={classes.tableCell}>
                            {pedido.id}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {" "}
                            {pedido.cliente.name}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {pedido.cliente.telefone}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {moment(pedido.horario).format(
                              "DD/MM/YYYY - HH:mm"
                            )}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            R${" "}
                            {parseFloat(pedido.valor_total).toLocaleString(
                              "pt-BR",
                              formato
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                      <div className={classes.paginationDiv}>
                        <Pagination
                          count={lastPage}
                          page={page}
                          onChange={(event, page) =>
                            handleChangePage(event, page)
                          }
                          color="secondary"
                        />
                      </div>
                    </TableBody>
                  </Table>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </>
      )}
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleClose}
        TransitionComponent={Grow}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              color="#000"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.titleOrder}>
              Pedido N&ordm; {detalhesPedido.id}
            </Typography>
          </Toolbar>
        </AppBar>
        <GridContainer spacing={5}>
          <Grid item xs={6} className={classes.orderContainer}>
            <List>
              <ListItem button className={classes.ListItem}>
                <ListItemText primary="Cliente" secondary={cliente.name} />
              </ListItem>
              <Divider />
              <ListItem button className={classes.ListItem}>
                <ListItemText
                  primary="Telefone"
                  secondary={cliente.telefone}
                  onClick={() => {
                    window.open(
                      `https://api.whatsapp.com/send?phone=55${cliente.telefone}&text=Olá, ${cliente.name}`,
                      "_blank"
                    );
                  }}
                />
              </ListItem>
              <Divider />
              <ListItem button className={classes.ListItem}>
                <ListItemText
                  primary="Horário"
                  secondary={moment(detalhesPedido.horario).format(
                    "DD/MM/YYYY - HH:mm"
                  )}
                />
              </ListItem>
              <Divider />
              <ListItem button className={classes.ListItem}>
                <ListItemText
                  primary="Observações do pedido"
                  secondary="Fazer"
                />
              </ListItem>
              <Divider />
            </List>
          </Grid>
          <Grid item xs={6} className={classes.orderContainer}>
            <List>
              <ListItem button className={classes.ListItem}>
                <ListItemText primary="Email" secondary={cliente.email} />
              </ListItem>
              <Divider />
              <ListItem button className={classes.ListItem}>
                <ListItemText
                  primary="Forma Pagamento"
                  secondary={detalhesPedido.forma_pagamento}
                />
              </ListItem>
              <Divider />
              {detalhesPedido.endereco_entrega !== "Retirada Balcão" ? (
                <>
                  <ListItem button className={classes.ListItem}>
                    <ListItemText
                      primary="Endereço"
                      secondary={` ${enderecoEntrega.logradouro},
           ${enderecoEntrega.numero_casa} - 
           ${enderecoEntrega.bairro}, 
           ${enderecoEntrega.cep} `}
                    />
                  </ListItem>
                  <Divider />
                </>
              ) : (
                <>
                  <ListItem button className={classes.ListItem}>
                    <ListItemText
                      primary="Retirada Balcão"
                      secondary="&nbsp;"
                    />
                  </ListItem>
                  <Divider />
                </>
              )}

              {detalhesPedido.endereco_entrega !== "Retirada Balcão" ? (
                <>
                  <ListItem button className={classes.ListItem}>
                    <ListItemText
                      primary="Taxa de Entrega"
                      secondary={`R$
               ${parseFloat(detalhesPedido.valor_entrega).toLocaleString(
                        "pt-BR",
                        formato
                      )} `}
                    />
                  </ListItem>
                </>
              ) : (
                <>
                  <ListItem button className={classes.ListItem}>
                    <ListItemText
                      primary="Taxa de Entrega"
                      secondary="R$ 0,00 (Balcão)"
                    />
                  </ListItem>
                </>
              )}

              <Divider />
            </List>
          </Grid>
          <br /> <br />
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.title2}>
              Produtos
            </Typography>
            <br /> <br />
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Produto</TableCell>
                  <TableCell></TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    R$
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {produtosPedido.map((produto) => (
                  <TableRow key={produto.id}>
                    <TableCell>
                      {produto.categoria === "Pizzas Salgadas" ||
                        produto.categoria === "Pizzas Doces" ? (
                        <>
                          <b>
                            {" "}
                            {produto.quantidade}x {produto.sub_categoria}
                          </b>
                          <br />
                          {produto.sabor3 ? (
                            <>
                              <br />
                              &#8531; {produto.sabor1}{" "}
                              {produto.observacoes_sabor1 ? (
                                <>
                                  <br />
                                  <small>
                                    (Obs: {produto.observacoes_sabor1})
                                  </small>
                                </>
                              ) : (
                                ""
                              )}{" "}
                              {produto.adicionais_sabor1 ? (
                                <>
                                  <br />
                                  <small>
                                    (Adicionais:{" "}
                                    {produto.adicionais_sabor1
                                      .replace("{", "")
                                      .replace("}", "")
                                      .replace(" ", "")}
                                    )
                                  </small>
                                </>
                              ) : (
                                ""
                              )}
                              <br />
                              <br />
                              &#8531; {produto.sabor2}{" "}
                              {produto.observacoes_sabor2 ? (
                                <>
                                  <br />
                                  <small>
                                    (Obs: {produto.observacoes_sabor2})
                                  </small>
                                </>
                              ) : (
                                ""
                              )}{" "}
                              {produto.adicionais_sabor2 ? (
                                <>
                                  <br />
                                  <small>
                                    (Adicionais:{" "}
                                    {produto.adicionais_sabor2
                                      .replace("{", "")
                                      .replace("}", "")
                                      .replace(" ", "")}
                                    )
                                  </small>
                                </>
                              ) : (
                                ""
                              )}
                              <br />
                              <br />
                              &#8531; {produto.sabor3}{" "}
                              {produto.observacoes_sabor3 ? (
                                <>
                                  <br />
                                  <small>
                                    (Obs: {produto.observacoes_sabor3})
                                  </small>
                                </>
                              ) : (
                                ""
                              )}{" "}
                              {produto.adicionais_sabor3 ? (
                                <>
                                  <br />
                                  <small>
                                    (Adicionais:{" "}
                                    {produto.adicionais_sabor3
                                      .replace("{", "")
                                      .replace("}", "")
                                      .replace(" ", "")}
                                    )
                                  </small>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )}
                          {produto.sabor2 && !produto.sabor3 ? (
                            <>
                              <br />
                              &frac12; {produto.sabor1}{" "}
                              {produto.observacoes_sabor1 ? (
                                <>
                                  <br />
                                  <small>
                                    (Obs: {produto.observacoes_sabor1})
                                  </small>
                                </>
                              ) : (
                                ""
                              )}{" "}
                              {produto.adicionais_sabor1 ? (
                                <>
                                  <br />
                                  <small>
                                    (Adicionais:{" "}
                                    {produto.adicionais_sabor1
                                      .replace("{", "")
                                      .replace("}", "")
                                      .replace(" ", "")}
                                    )
                                  </small>
                                </>
                              ) : (
                                ""
                              )}
                              <br />
                              <br />
                              &frac12; {produto.sabor2}{" "}
                              {produto.observacoes_sabor2 ? (
                                <>
                                  <br />
                                  <small>
                                    (Obs: {produto.observacoes_sabor2})
                                  </small>
                                </>
                              ) : (
                                ""
                              )}{" "}
                              {produto.adicionais_sabor2 ? (
                                <>
                                  <br />
                                  <small>
                                    (Adicionais:{" "}
                                    {produto.adicionais_sabor2
                                      .replace("{", "")
                                      .replace("}", "")
                                      .replace(" ", "")}
                                    )
                                  </small>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )}
                          {!produto.sabor2 && !produto.sabor3 ? (
                            <>
                              <br />
                              {produto.sabor1}{" "}
                              {produto.observacoes_sabor1 ? (
                                <>
                                  <br />
                                  <small>
                                    (Obs: {produto.observacoes_sabor1})
                                  </small>
                                </>
                              ) : (
                                ""
                              )}{" "}
                              {produto.adicionais_sabor1 ? (
                                <>
                                  <br />
                                  <small>
                                    (Adicionais:{" "}
                                    {produto.adicionais_sabor1
                                      .replace("{", "")
                                      .replace("}", "")
                                      .replace(" ", "")}
                                    )
                                  </small>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )}
                          <br />
                          <br />
                          <small>
                            {produto.massa_pizza}{" "}
                            {produto.valor_massa_pizza
                              ? `R$ ${parseFloat(produto.valor_massa_pizza)}`
                              : ""}
                          </small>
                          <br />
                          <small>
                            {produto.borda_pizza}{" "}
                            {produto.valor_borda_pizza
                              ? `R$ ${parseFloat(produto.valor_borda_pizza)}`
                              : ""}
                          </small>
                        </>
                      ) : (
                        <>
                          <b>
                            {" "}
                            {produto.quantidade}x {produto.sub_categoria}
                          </b>
                          <br />
                          {produto.produto}
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {/* <DeleteIcon
                onClick={() => {
                  handleDelete(produto);
                }}
                size="small"
                className={classes.redIcon}
              /> */}
                    </TableCell>
                    <TableCell align="right">
                      {parseFloat(produto.valor_produto).toLocaleString(
                        "pt-BR",
                        formato
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                <br />
                <br />
                <TableRow>
                  <TableCell align="right" colSpan={2}>
                    <small>Subtotal</small>
                  </TableCell>
                  <TableCell align="right">
                    {parseFloat(detalhesPedido.sub_total).toLocaleString(
                      "pt-BR",
                      formato
                    )}
                  </TableCell>
                </TableRow>

                {detalhesPedido.cupom ? (
                  <TableRow>
                    <TableCell align="right" colSpan={2}>
                      <small>(Cupom: {detalhesPedido.cupom})</small>
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      -{" "}
                      {parseFloat(detalhesPedido.valor_desconto).toLocaleString(
                        "pt-BR",
                        formato
                      )}
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                <TableRow>
                  <TableCell align="right" colSpan={2}>
                    <small>Entrega</small>
                  </TableCell>

                  <TableCell align="right">
                    {detalhesPedido.endereco_entrega == "Retirada Balcão"
                      ? "0,00"
                      : ` ${parseFloat(
                        detalhesPedido.valor_entrega
                      ).toLocaleString("pt-BR", formato)}`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right" colSpan={2}>
                    Total
                  </TableCell>
                  <TableCell align="right">
                    {parseFloat(detalhesPedido.valor_total).toLocaleString(
                      "pt-BR",
                      formato
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </GridContainer>
        <br />
        <br />
        <br />
      </Dialog>
    </>
  );
}
